<template>
  <div class="notice">
    <div class="notice-body">
      <div class="notice-title">
        待取货列表：

        <img :src="codeImage" @click="showCode" />
      </div>
      <div class="goods-list">
        <div class="goods-info" v-for="(goods, goodsIndex) in showList">
          <div class="goods-img">
            <img :src="goods.path" />
          </div>
          <div class="info">
            <div class="goods-title">
              {{ goods.title }}
            </div>
            <div class="goods-number">
              <span>{{ goods.spec_text ? goods.spec_text : "默认规格" }}</span
              >× {{ goods.total }}
            </div>
          </div>
        </div>
      </div>

      <div class="notice-title">
        售后取货列表：
        <img :src="codeImage" @click="showAfterSaleCode" />
      </div>

      <div class="goods-list">
        <div class="goods-info" v-for="(item, index) in afterSaleGoodsShowList">
          <div class="goods-img">
            <img :src="item.path" />
          </div>
          <div class="info">
            <div class="goods-title">
              {{ item.title }}
            </div>
            <div class="goods-number">
              <span>{{ item.spec_text ? item.spec_text : "默认规格" }}</span
              >× {{ item.total }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="buttons">
      <button class="btn" @click="showConfirm">确认取货</button>
    </div>
    <nut-popup
      v-model="show"
      round
      :closeable="closeable"
      position="bottom"
      :style="{ height: '40%' }"
      :close-on-click-overlay="false"
      @close="goHome"
    >
      <div class="confirm-window">
        <div class="title">确认取货提示</div>
        <template v-if="!showResult">
          <div class="content">
            请再次确认您已取到页面中展示的商品，当您点击确认取货按钮后，系统将更新这些商品的配送状态！
          </div>
          <div class="buttons">
            <button class="btn" @click="confirmGetGoods">确认取货</button>
          </div>
        </template>
        <div class="result" v-if="showResult">
          <nut-icon type="tick" style="margin-right: 10px"></nut-icon>
          取货状态更新成功
        </div>
      </div>
    </nut-popup>

    <nut-popup
      v-model="showDeliveryCode"
      round
      :closeable="closeable"
      position="bottom"
      :style="{ height: '80%' }"
      :close-on-click-overlay="false"
      @close="closeCode"
    >
      <div class="confirm-window">
        <div class="title">配送员提货码</div>
        <img :src="deliveryCode" />
      </div>
    </nut-popup>

    <nut-popup
      v-model="showDeliveryAfterSalesCode"
      round
      :closeable="closeable"
      position="bottom"
      :style="{ height: '80%' }"
      :close-on-click-overlay="false"
      @close="closeCode"
    >
      <div class="confirm-window">
        <div class="title">配送员售后提货码</div>
        <img :src="deliveryAfterCode" />
      </div>
    </nut-popup>
  </div>
</template>

<script>
import searchV1 from "../../template/community/search/searchV1";
import CellV3 from "../../template/community/cell/cellV3";
import { hideLoading, showLoading } from "@uni/loading";
import codeImage from "../../static/image/icons/code.png";

export default {
  name: "courier-notice",
  components: { CellV3, searchV1 },
  data() {
    return {
      keyword: "",
      page: 1,
      list: [],
      showList: [],
      scrollHeight: 0,
      show: false,
      confirmWidth: window.innerWidth * 0.8,
      closeable: true,
      showResult: false,
      codeImage,
      deliveryCode: "",
      deliveryAfterCode: "",
      showDeliveryCode: false,
      showDeliveryAfterSalesCode: false,
      afterSaleGoodsList: [],
      afterSaleGoodsShowList: [],
    };
  },
  mounted() {
    this.getNoticeList();
    this.scrollHeight = window.innerHeight;
    document.title = "等待取货商品";
  },
  methods: {
    goHome() {
      if (this.$platform.wxsdk.isWechat()) {
        this.$platform.wxsdk.wxRoute({ type: "navigateBack" });
      } else {
        this.$router.push({ path: "self-extract-home" });
      }
    },
    closeCode() {
      this.showDeliveryCode = false;
      this.showDeliveryAfterSalesCode = false;
    },
    async getNoticeList() {
      try {
        let query = await this.$api.community.courier.getNoticeList();
        if (query.data.length == 0) {
          return;
        }
        this.list = query.data.goodsList;
        this.showList = query.data.showGoodsList;
        this.afterSaleGoodsList = query.data.afterGoodsList;
        this.afterSaleGoodsShowList = query.data.showAfterGoodsList;
        console.log("afterSaleGoodsList", this.afterSaleGoodsList);
        console.log("afterSaleGoodsShowList", this.afterSaleGoodsShowList);
      } catch (e) {
        // console.log("通知列表获取失败");
      }
    },
    showConfirm() {
      this.show = true;
    },
    confirmGetGoods() {
      let params = this.list.map((item) => {
        return {
          goods_id: item.goods_id,
          spec_ids: item.spec_ids,
          distribution_id: item.distribution_id,
          site_point_id: item.site_point_id,
        };
      });
      let afterSaleParams = this.afterSaleGoodsList.map((item) => {
        return {
          goods_id: item.goods_id,
          spec_ids: item.spec_ids,
        };
      });

      showLoading({
        content: "取货状态更新中",
      });

      this.closeable = false;

      console.log(JSON.stringify({ settings: params }));

      this.$api.community.courier
        .confirmGetGoods({
          settings: params,
          afterSaleSettings: afterSaleParams,
        })
        .then((response) => {
          this.getNoticeList();

          setTimeout(() => {
            this.showResult = true;
            this.closeable = true;
            hideLoading();
            this.$forceUpdate();
          }, 300);
        });
    },
    showCode() {
      this.$api.community.courier.deliveryCode().then((response) => {
        this.deliveryCode = response.data;
        this.showDeliveryCode = true;
      });
    },
    showAfterSaleCode() {
      this.$api.community.courier.deliveryAfterCode().then((response) => {
        this.deliveryAfterCode = response.data;
        this.showDeliveryAfterSalesCode = true;
      });
    },
  },
};
</script>

<style scoped lang="scss">
.notice {
  width: 100vw;
  height: 100vh;
  background: #f6f6f6;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .notice-title {
    background: #fff;
    padding: 10px;
    margin-bottom: 10px;
    font-size: 16px;
    position: relative;

    img {
      position: absolute;
      right: 5px;
      top: 5px;
      width: 25px;
      height: 25px;
    }
  }

  .notice-body {
    flex-grow: 1;
    box-sizing: border-box;
    padding: 10px 10px 0 10px;
    overflow-y: scroll;
    height: calc(100% - 60px);
    overflow-y: scroll;

    .goods-list {
      flex: 1;
      display: flex;
      flex-direction: column;

      .goods-info {
        flex: 1;
        display: flex;
        flex-direction: row;
        margin-bottom: 10px;
        background: #fff;
        padding: 10px;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        border-radius: 5px;

        .goods-img {
          width: 70px;
          height: 70px;
          -webkit-border-radius: 5px;
          -moz-border-radius: 5px;
          border-radius: 5px;
          overflow: hidden;
          margin-right: 10px;

          img {
            width: 70px;
            height: 70px;
          }
        }

        .info {
          flex: 1;
          display: flex;
          flex-direction: column;

          .goods-title {
            font-size: 16px;
            margin-top: 10px;
          }

          .goods-specs {
            font-size: 14px;
            color: #ccc;
            margin-top: 6px;
          }

          .goods-number {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            font-size: 20px;
            color: red;
            font-weight: bold;
            margin-top: 15px;

            span {
              font-size: 14px;
              margin-right: 20px;
              font-weight: 300;
              color: #333;
            }
          }
        }
      }
    }
  }

  .buttons {
    background: #fff;
    height: 60px;
    padding: 10px 50px;
    display: flex;

    .btn {
      flex: 1;
      bottom: calc(30px + env(safe-area-inset-bottom));
      bottom: calc(30px + constant(safe-area-inset-bottom));
      display: flex;
      align-items: center;
      justify-content: center;
      background: linear-gradient(139deg, #fac484 0%, #f46a17 100%);
      background-clip: padding-box;
      border: 4px solid rgba(244, 106, 23, 0.15);
      border-radius: 29px;
      font-size: 16px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 400;
      color: #ffffff;
    }
  }
}

.confirm-window {
  display: flex;
  flex-direction: column;

  .title {
    flex: 1;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgba(0, 0, 0, 0.6);
    padding: 15px 0;
  }

  .content {
    font-size: 16px;
    color: #333;
    padding: 30px 50px;
    line-height: 25px;
  }

  .result {
    height: 200px;
    display: flex;
    justify-content: center;
    font-size: 14px;
    color: #333;
    align-items: center;
  }
}
</style>
